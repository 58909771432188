<template>
    <div>
        <div class="container py-3 py-lg-6">
            <div class="px-lg-7">
                <iframe class="w-100 yt-video" style="" src="https://www.youtube.com/embed/H4FZPDVeDpY" title="E Auction demo"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TutorialPage'
};
</script>

<style scoped lang="scss">
.yt-video {
    min-height: 12rem;
    @media (min-width: 992px) {
        min-height: 25rem !important;
    }
}
</style>
